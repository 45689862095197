import { CATEGORIES } from './categories'
import C from '@utilities/constants'

const TRUCKLOADS = '/category/truckloads/1'
const ALL_AUCTIONS = '/category/all/1'
const ALL_ITEMS = '/items?pageId=1'
const EQUIPMENT = '/mnr-auctions/all/1'
const PALLET_LOT = '/category/pallet-lots/1'

const CATEGORY_LIST = Object.values(CATEGORIES)

export const NAVIGATION = {
  header: [
    {
      label: 'Items',
      route: ALL_ITEMS,
    },
    {
      label: 'Auctions',
      items: [
        { label: 'Shop All Auctions', route: ALL_AUCTIONS },
        { label: 'Truckloads', route: TRUCKLOADS },
        { label: 'Equipment Auctions', route: EQUIPMENT },
        { label: 'Pallet Lot', route: PALLET_LOT },
      ],
    },
    {
      label: 'Categories',
      items: [
        { label: 'Shop All Items', route: ALL_ITEMS, isSpecial: true },
        ...CATEGORY_LIST,
        {
          label: 'Shop Seasonal',
          route: '/campaign/post-holiday',
          isSpecial: true,
        },
        {
          label: "See What's New",
          route: '/items?pageId=1&itemConditionIds=5&itemConditionIds=14',
          isSpecial: true,
        },
        { label: "Auction How-To's", route: '/blog', isSpecial: true },
      ],
      isSpecial: true,
    },
    { label: 'BidFTA+', route: '/bidfta-plus' },
    { label: 'Bin Store', route: '/bin-store' },
  ],
  sidebar: [
    {
      label: 'Shop all Items',
      route: ALL_ITEMS,
    },
    {
      label: 'Shop all Auctions',
      route: ALL_AUCTIONS,
      isMobileOnly: true,
    },
    {
      label: 'Categories',
      items: [
        {
          label: 'Shop All Items',
          route: ALL_ITEMS,
          isSpecial: true,
        },
        ...CATEGORY_LIST,
      ],
      isMobileOnly: true,
    },
    { label: 'BidFTA+', route: '/bidfta-plus', isMobileOnly: true },
    {
      label: 'Locations',
      route: '/location',
      main: true,
    },
    { label: 'Bin Store', route: '/bin-store', isMobileOnly: true },
    { label: 'How-To Guides', route: '/blog', isSpecial: true },
    {
      label: 'Support',
      route: 'https://wefasttrackit.zendesk.com/hc/en-us/requests/new',
      isSpecial: true,
    },
    { label: 'Sell With Us', route: '/sell-with-us', isSpecial: true },
    { label: 'Shop Truckloads', route: TRUCKLOADS, isMobileOnly: true },
    { label: 'Equipment Auctions', route: EQUIPMENT, isMobileOnly: true },
    { label: 'FAQ', route: '/faq', isSpecial: true },
  ],
  footer: {
    auctions: {
      label: 'Auctions',
      items: [
        { label: 'Locations', route: '/location' },
        { label: 'Equipment Auctions', route: EQUIPMENT },
        {
          label: 'Building Materials',
          route: '/category/building-materials/1',
        },
        { label: 'Truckloads', route: TRUCKLOADS },
        { label: 'Past Auctions', route: '/past-auctions/all/1' },
      ],
    },
    categories: {
      label: 'Categories',
      items: [
        ...CATEGORY_LIST.filter(({ isFooter }) => isFooter),
        {
          label: 'SHOP ALL CATEGORIES',
          route: ALL_ITEMS,
          isSpecial: true,
        },
      ],
    },
    resources: {
      label: 'Resources',
      items: [
        { label: 'Sell With Us', route: '/sell-with-us' },
        { label: 'How-To Guides', route: '/blog' },
        { label: 'Bin Store', route: '/bin-store' },
        { label: 'BidFTA+', route: '/bidfta-plus' },
        { label: '', route: '' },
        { label: 'Privacy Policy', route: '/privacy-policy' },
        { label: 'Terms & Conditions', route: '/terms' },
        { label: 'Careers', route: C.LINKS.CAREERS },
      ],
    },
  },
}
