import '@assets/main.css'
import '@assets/chrome-bug.css'
import 'keen-slider/keen-slider.min.css'
import '../styles/globals.scss'
import '../styles/nprogress.css'

import React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { AuthProvider } from '../contexts/AuthContext'
import { GlobalUIProvider } from 'contexts/GlobalUIContext'
import TopProgressBar from '@components/TopProgressBar/TopProgressBar'
import { HistoryProvider } from '../contexts/HistoryContext'
import { GlobalDataProvider } from '../contexts/GlobalDataContext'
import TagManager from 'react-gtm-module'
import { Raleway, League_Spartan, Lato } from '@next/font/google'
import Logout from '@components/Logout'
import MissingCreditCard from '@components/MissingCreditCard'
import BackToTop from '@components/BackToTop'
import { ThemeProvider } from 'next-themes'
import Script from 'next/script'
import NextHead from 'next/head'
import { datadogRum } from '@datadog/browser-rum'
import packageJson from '../package.json'
import { SessionProvider } from 'next-auth/react'
import { NavigationProvider } from '../contexts/NavigationContext'

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APP_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATA_DATA_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'bidftaweb',
  env: process.env.NEXT_PUBLIC_ENVIRONMENT || '',
  version: packageJson.version,
  sessionSampleRate: 1,
  sessionReplaySampleRate: 1,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

const raleway = Raleway({
  subsets: ['latin'],
  weight: ['400', '700'],
  variable: '--font-raleway',
})

const leagueSpartan = League_Spartan({
  subsets: ['latin'],
  weight: ['400', '700'],
  variable: '--font-league-spartan',
})

const lato = Lato({
  subsets: ['latin'],
  weight: ['400', '700'],
  variable: '--font-lato',
})

const Noop: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const Layout = (Component as any).Layout || Noop
  const ComponentTyping = Component as any

  useEffect(() => {
    document.body.classList?.remove('loading')
    TagManager.initialize({ gtmId: 'GTM-5W2JNKF' }) // old data
    TagManager.initialize({ gtmId: 'GTM-56HC8XM2' }) // marketing new
    TagManager.initialize({ gtmId: 'GTM-W2RTMKHG' }) // custom events
  }, [])

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('340924541426797') // facebookPixelId
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  // TODO: Resolve first compile error in _app.tsx
  // return <div>Error: Maximum call stack size exceeded</div>

  return (
    <div
      className={`${raleway.variable} ${lato.variable} ${leagueSpartan.variable} font-sans`}
    >
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      </NextHead>
      <Script
        src="https://99df759d4e9d4facac817c80b49fb66f.js.ubembed.com"
        strategy="afterInteractive"
      />
      <SessionProvider session={pageProps.session}>
        <AuthProvider>
          <NavigationProvider>
            <GlobalDataProvider>
              <HistoryProvider>
                <ThemeProvider>
                  <GlobalUIProvider>
                    <Logout />
                    <MissingCreditCard />
                    <TopProgressBar />
                    <Layout pageProps={pageProps}>
                      <ComponentTyping {...pageProps} />
                    </Layout>
                  </GlobalUIProvider>
                </ThemeProvider>
                <BackToTop />
              </HistoryProvider>
            </GlobalDataProvider>
          </NavigationProvider>
        </AuthProvider>
      </SessionProvider>
    </div>
  )
}

export default MyApp
